import { useState } from 'react';
import { getSticker } from '@/services/chat';

export default function useEmojis() {
  const [sticker, setSticker ] = useState([]);

  // 定义手动触发的表情包获取函数
  const fetchSticker = async () => {
    // 如果已经有数据，不再请求
    if (sticker && sticker.length > 0) return;

    try {
      const res = await getSticker();
      setSticker(res?.data || []); // 将数据保存到全局状态中
    } catch (error) {
      console.error('sticker error', error);
    }
  };

  return {
    sticker,
    fetchSticker,
  };
}
