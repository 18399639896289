import { history } from '@umijs/max';
import { message, notification } from 'antd';

import type { RequestOptions } from '@@/plugin-request/request';
import type { RequestConfig } from '@umijs/max';


// 错误处理方案： 错误类型
enum ErrorShowType {
  SILENT = 0,
  WARN_MESSAGE = 1,
  ERROR_MESSAGE = 2,
  NOTIFICATION = 3,
  REDIRECT = 9,
}
// 与后端约定的响应数据格式
interface ResponseStructure {
  success: boolean;
  data: any;
  errorCode?: number;
  errorMessage?: string;
  showType?: ErrorShowType;
}

/**
 * @name 错误处理
 * pro 自带的错误处理， 可以在这里做自己的改动
 * @doc https://umijs.org/docs/max/request#配置
 */
export const errorConfig: RequestConfig = {
  // 错误处理： umi@3 的错误处理方案。
  errorConfig: {
    // 错误抛出
    errorThrower: (res) => {
      const { success, data, errorCode, errorMessage, showType } =
        res as unknown as ResponseStructure;
      if (!success) {
        const error: any = new Error(errorMessage);
        error.name = 'BizError';
        error.info = { errorCode, errorMessage, showType, data };
        throw error; // 抛出自制的错误
      }
    },
    // 错误接收及处理
    errorHandler: (error: any, opts: any) => {
      if (opts?.skipErrorHandler) throw error;
      // 我们的 errorThrower 抛出的错误。
      if (error.name === 'BizError') {
        const errorInfo: ResponseStructure | undefined = error.info;
        if (errorInfo) {
          const { errorMessage, errorCode } = errorInfo;
          switch (errorInfo.showType) {
            case ErrorShowType.SILENT:
              // do nothing
              break;
            case ErrorShowType.WARN_MESSAGE:
              message.warning(errorMessage);
              break;
            case ErrorShowType.ERROR_MESSAGE:
              message.error(errorMessage);
              break;
            case ErrorShowType.NOTIFICATION:
              notification.open({
                description: errorMessage,
                message: errorCode,
              });
              break;
            case ErrorShowType.REDIRECT:
              // TODO: redirect
              break;
            default:
              message.error(errorMessage);
          }
        }
      } else if (error.response) {
        // Axios 的错误
        // 请求成功发出且服务器也响应了状态码，但状态代码超出了 2xx 的范围
        if (error.response.status === 401) {
          message.error(`登录过期，请重新登录`);
          localStorage.removeItem('accessToken'); // 清除过期的 token
          history.push('/login');
        } else {
          const data = error.response.data.data;
          const isErrorType = typeof data === 'string';
          if (isErrorType) {
            message.error(data);
          } else {
            message.error(data.message);
          }
        }
      } else if (error.request) {
        // 请求已经成功发起，但没有收到响应
        message.error('服务器暂时没有响应');
      } else {
        // 发送请求时出了点问题
        message.error('请稍后重试哦');
      }
    },
  },

  // 请求拦截器
  requestInterceptors: [
    (requestConfig: RequestOptions) => {
      const newConfig = requestConfig;

      // 拦截请求配置，进行个性化处理。
      const token = localStorage.getItem('accessToken') || '';
      let deviceId = ''; // 设备ID

      if (window?.aplus?.getMetaInfo && window?.aplus?.getMetaInfo('_dev_id')) {
        deviceId = window?.aplus?.getMetaInfo('_dev_id');
      }

      // 在请求头上添加 Authorization 和 X-Device-id
      newConfig.headers = {
        ...newConfig.headers,
        Authorization: `Bearer ${token}`,
        'X-Device-id': deviceId,
      };

      return newConfig;
    },
  ],

  // 响应拦截器
  responseInterceptors: [
    (response) => {
      // 拦截响应数据，进行个性化处理
      const { data } = response as unknown as ResponseStructure;

      if (data?.success === false) {
        message.error('请求失败！');
      }
      return response;
    },
  ],
};
