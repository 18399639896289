import { request } from '@umijs/max';


/** 进程提交 */
export async function process(body?: any) {
  return request<any>('/api/v1/event/process_feedback', {
    method: 'POST',
    data: body,
  });
}

/** 获取对话 */
export async function getConversation(params?: any) {
  return request<any>('/api/v1/chat/conversation/default', {
    method: 'GET',
    params: {
      ...params,
    },
  });
}

/** 更新对话 */
export async function updateConversation(body?: any) {
  return request<any>('/api/v1/chat/conversation', {
    method: 'POST',
    data: body,
  });
}

/** 获取消息 */
export async function getMessage(params?: any) {
  return request<any>('/api/v1/chat/message', {
    method: 'GET',
    params: {
      ...params,
    },
  });
}

/** 发送消息 */
export async function sendMessage(body?: any) {
  return request<any>('/api/v1/chat/message', {
    method: 'POST',
    data: body,
  });
}

// 生成语音
export async function createAudio(params?: any) {
  return request<any>('/api/v1/audio', {
    method: 'GET',
    params: {
      ...params,
    },
  });
}

/** 再生对话 */
export async function regenerate(body?: any) {
  return request<any>('/api/v1/chat/message/regenerate', {
    method: 'POST',
    data: body,
  });
}

/** 获取表情包 */
export async function getSticker(params?: any) {
  return request<any>('/api/v1/chat/sticker', {
    method: 'GET',
    params: {
      ...params,
    },
  });
}

