import { useState } from 'react';

export default function useGlobal() {
  const [motion, setMotion] = useState(null);

  return {
    motion,
    setMotion,
  };
};
