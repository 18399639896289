// 运行时配置
import { history } from '@umijs/max';
import type { RequestConfig } from '@umijs/max';
import { message } from 'antd';

import stompService from '@/services/stompService';
import { getUser } from '@/services/user';
import { getCharacter } from '@/services/character';

import { serverUrl } from '@/constants/api';
import { errorConfig } from './requestErrorConfig';

export async function getInitialState() {
  // 页面刷新和登录后
  const fetchUserInfo = async () => {
    try {
      const userData = await getUser(); // 获取用户信息
      const characterData = await getCharacter(); // 获取用户角色信息
      stompService.connect(userData?.data?.id); // WebSocket 连接初始化
      return {
        ...userData.data,
        characterData: {
          ...characterData.data,
        },
      };
    } catch (error) {
      history.push('/login');
    }
  };

  const accessToken = localStorage.getItem('accessToken');
  // const { location } = history;
  if (!accessToken) {
    return {
      fetchUserInfo,
    };
  } else {
    const currentUser = await fetchUserInfo();
    return {
      currentUser,
      fetchUserInfo,
    };
  }
}

/**
 * @name request 配置，可以配置错误处理
 * 它基于 axios 和 ahooks 的 useRequest 提供了一套统一的网络请求和错误处理方案。
 * @doc https://umijs.org/docs/max/request#配置
 */
export const request: RequestConfig = {
  baseURL: serverUrl[process.env.UMI_ENV],
  ...errorConfig,
};


export function rootContainer(container: React.ReactNode) {

  // 穿透设置
  window?.electron?.setIgnoreMouseEvents(false);

  // 配置消息设置
  message.config({
    getContainer: () => document.getElementById('root_default'),
  });

  return container;
}
