import { Client, IMessage } from '@stomp/stompjs';

import { websocketSubscribeURL, websocketSubscribeVhost } from '@/constants/api';

class StompService {
  private stompClient: Client | null;
  private isConnected: boolean;
  private subscriptions: Map<
    string,
    {
      topic: string;
      callback: (msg: any) => void;
      subscription: any;
      options: any;
    }
  >;

  constructor() {
    this.stompClient = null;
    this.isConnected = false;
    this.subscriptions = new Map();
  }

  // 连接 STOMP
  connect(userId: string) {
    if (this.stompClient && this.stompClient.connected) {
      return; // 防止重复连接
    }

    const accessToken = localStorage.getItem('accessToken');
    if (!userId && !accessToken) {
      return;
    }

    this.stompClient = new Client({
      brokerURL: `${websocketSubscribeURL[process.env.UMI_ENV]}?jwt_token=${accessToken}`,
      connectHeaders: {
        login: userId,
        passcode: accessToken,
        host: websocketSubscribeVhost[process.env.UMI_ENV],
        // host: 'poppaaws_testing',
      },
      reconnectDelay: 1000,
      // debug: (str) => {
      //   console.log(str);
      // },
      onConnect: (frame) => {
        // console.log('Connected1: ', frame);
        this.isConnected = true;

        // 连接成功后，订阅已保存的 topic
        this.subscriptions.forEach(({ topic, callback, options }) => {
          this.subscribeToTopic(topic, callback, options);
        });
        this.subscriptions.clear(); // 清空订阅队列
      },
      onStompError: (frame) => {
        console.error('error: ' + frame.headers['message']);
        console.error('details: ' + frame.body);
      },
    });

    this.stompClient.activate();
  }

  // 断开 STOMP 连接
  disconnect() {
    if (this.stompClient && this.stompClient.connected) {
      this.stompClient.deactivate();
      this.isConnected = false;
    }
  }

  // 内部方法，订阅指定 topic
  private subscribeToTopic(
    topic: string,
    callback: (msg: any) => void,
    options: any = {},
  ) {
    if (!this.stompClient) {
      return;
    }

    const subscription = this.stompClient.subscribe(
      topic,
      (message: IMessage) => {
        callback(message);
      },
      options,
    );

    // 存储订阅信息，供以后取消订阅时使用
    this.subscriptions.set(topic, {
      topic,
      callback,
      subscription,
      options,
    });
  }

  // 外部订阅接口
  subscribe(topic: string, callback: (msg: any) => void, options?: any) {
    // 如果没有传递 options，则使用空对象作为默认值
    const finalOptions = options || {};

    if (this.isConnected && this.stompClient) {
      this.subscribeToTopic(topic, callback, finalOptions);
    } else {
      // 如果连接未建立，存储待订阅的 topic 和回调函数
      this.subscriptions.set(topic, {
        topic,
        callback,
        subscription: null,
        options: finalOptions,
      });
    }
  }

  // 取消订阅
  unsubscribe(topic: string) {
    const subscriptionInfo = this.subscriptions.get(topic);
    if (subscriptionInfo && subscriptionInfo.subscription) {
      subscriptionInfo.subscription.unsubscribe();
      this.subscriptions.delete(topic); // 删除订阅信息
    }
  }

  // 取消所有订阅
  unsubscribeAll() {
    this.subscriptions.forEach((subscriptionInfo, topic) => {
      this.unsubscribe(topic);
    });
  }

  // 判断是否已经连接
  isConnectedStatus() {
    return this.isConnected;
  }
}

const stompService = new StompService();
export default stompService;
